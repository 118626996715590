<button mat-icon-button [matMenuTriggerFor]="flagsMenu" #flagsMenuTrigger="matMenuTrigger" *ngIf="language">
    <img [src]="language.src" [alt]="language.name" width="20" />
</button>
<!--<button mat-icon-button [matMenuTriggerFor]="flagsMenu" #flagsMenuTrigger="matMenuTrigger">
    <img src="assets/img/flags/gb.svg" width="20" alt="english"> 
</button>-->
<mat-menu #flagsMenu="matMenu" [overlapTrigger]="false" class="toolbar-dropdown-menu flags">
    <span>  
        <ng-container *ngFor="let lang of languages">
            <button mat-menu-item (click)="setLanguage(lang.code)">
                <img [src]="lang.src" width="20" [alt]="lang.name"> 
                {{ lang.name }}
            </button>
        </ng-container>
    </span>
</mat-menu>
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER  } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';
import { Router } from '@angular/router';

import { AgmCoreModule } from '@agm/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true               
};

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SharedModule } from './shared/shared.module';
import { PipesModule } from './theme/pipes/pipes.module';
import { routing } from './app.routing';

import { AppSettings } from './app.settings';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { NotAllowedComponent } from './pages/errors/not-allowed/not-allowed.component';
import { ErrorComponent } from './pages/errors/error/error.component';

import { TopInfoContentComponent } from './theme/components/top-info-content/top-info-content.component';
import { SidenavComponent } from './theme/components/sidenav/sidenav.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { FlagsMenuComponent } from './theme/components/flags-menu/flags-menu.component';
import { FullScreenComponent } from './theme/components/fullscreen/fullscreen.component';
import { ApplicationsComponent } from './theme/components/applications/applications.component';
import { FavoritesComponent } from './theme/components/favorites/favorites.component';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AuthInterceptor } from '@services/token.interceptor.service';

import { HttpClientModule  } from '@angular/common/http';
import { JwtModule, JwtModuleOptions  } from '@auth0/angular-jwt';
import { environment as env } from '@environments/environment';

// NGXS Store
import { NgxsModule } from '@ngxs/store';
import { AuthState } from '@store/state/auth.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { CustomMenuComponent } from './theme/components/menu/custom-menu/custom-menu.component';
import { UnauthorizedComponent } from './pages/errors/unauthorized/unauthorized.component';
import { TokenStoreProvider } from '@app/services/providers/token.store.provider';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { NgxLoadingModule } from 'ngx-loading';
import { PrintLayoutComponent } from './pages/print-layout/print-layout.component';
import { InvoiceState } from '@store/state/invoice.state'
import { TransactionState } from './store/state/transaction.state';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { TransactionDetailsPrintComponent } from './pages/print/transaction-details-print/transaction-details-print.component';
import { SwitchMerchantComponent } from './theme/components/switch-merchant/switch-merchant.component';
import { InactiveComponent } from './pages/errors/inactive/inactive.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

export function jwtTokenGetter() {
  return localStorage.getItem('token');
}

export function tokenStoreProvider(provider: TokenStoreProvider, router: Router) {
  return () => provider.load()
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const JWT_Module_Options: JwtModuleOptions = {
  config: {
      tokenGetter: jwtTokenGetter,
      whitelistedDomains: [env.BEAPI, env.API_BASE_URL]
  }
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAAYi6itRZ0rPgI76O3I83BhhzZHIgMwPg'
    }), 
    PerfectScrollbarModule,     
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    SharedModule.forRoot(),
    PipesModule,
    routing,
    HttpClientModule ,
    JwtModule.forRoot(JWT_Module_Options),
    NgxsModule.forRoot([
      AuthState,
      InvoiceState,
      TransactionState
    ]),
    NgxsStoragePluginModule.forRoot({
      key: ['auth.merchants','auth.selectedMerchant']
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({
      disabled: env.production
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxLoadingModule.forRoot({}),
    NgxMaskModule.forRoot(options),
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    BlankComponent,
    NotFoundComponent,
    ErrorComponent,
    TopInfoContentComponent,
    SidenavComponent,
    VerticalMenuComponent,
    CustomMenuComponent,
    FlagsMenuComponent,
    FullScreenComponent,
    ApplicationsComponent,
    FavoritesComponent,
    NotAllowedComponent,
    CustomMenuComponent,
    UnauthorizedComponent,
    PrintLayoutComponent,
    TransactionDetailsPrintComponent,
    SwitchMerchantComponent,
    InactiveComponent
  ],
  entryComponents: [
    CustomMenuComponent,
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    AppSettings,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    TokenStoreProvider,
    { provide: APP_INITIALIZER, useFactory: tokenStoreProvider, deps: [TokenStoreProvider], multi: true },


    // { provide: RouteReuseStrategy, useClass: CacheRouteReuseStrategy }
  ],
  bootstrap: [
    AppComponent  
  ]
})
export class AppModule { }


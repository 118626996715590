import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt'


@Injectable({
    providedIn: 'root'
})

export class TokenService {

    /**
     * Local server iss
     */
    private iss = {
        login: `${env.BEAPI + env.AUTH.local.login}`,
        google2fa: `${env.BEAPI + env.AUTH.local.google2fa}`,
        signup: `${env.BEAPI + env.AUTH.local.signup}`
    }

    /**
     * JWT Helper
     * 
     */
    private helper = new JwtHelperService();

    get (): string {
        return localStorage.getItem('token');
    }

    /**
     * Validate the token
     * 
     * @param token 
     */
    isValid(){

        const token = this.get();

        if(token){
            const payload = this.getPayload(token);

            if(payload){
                return Object.values(this.iss).indexOf(payload.iss) > -1 ? true : false;
            }
        }

        return false;

    }

    /**
     * Get token payload
     * 
     * @param token 
     */
    getPayload(token){

        const payload = token.split('.')[1];
        return this.decode(payload);

    }

    /**
     * Decode the token payload
     * 
     * @param payload 
     */
    decode(payload){
        
        try {
            return JSON.parse(atob(payload));
        }
        catch(e){
            return false;
        }

    }

    /**
     * Is user logged in
     * 
     */
    loggedIn(){

        return this.isValid();
        
    }

    /**
     * Check if Token is expired
     * 
     */
    isExpired() {

        const token = this.get();

        const isExpired = this.helper.isTokenExpired(token);

        return isExpired;
    }

}

<div class="sidenav-menu-outer" [perfectScrollbar]="psConfig" [disabled]="settings.fixedSidenavUserContent">    
    <div fxLayout="column" fxLayoutAlign="center center" class="user-block" [class.py-3]="settings.menuType != 'mini'" [class.py-2]="settings.menuType == 'mini'">
        <!-- <img src="assets/img/users/user.jpg" alt="user-image">
        <h2 *ngIf="settings.menuType != 'mini'" class="mt-2">Emilio Verdines</h2>
        <p *ngIf="settings.menuType == 'default'" class="secondary-text-color">emilio.verdines@company.com</p> -->
    </div>

    <app-switch-merchant *ngIf="!!showSwitchMerchant()"></app-switch-merchant>

    <mat-divider></mat-divider>
    <div id="vertical-menu" [perfectScrollbar]="psConfig" [disabled]="!settings.fixedSidenavUserContent">
        <app-vertical-menu [menuItems]="menuItems" [menuParentId]="0"></app-vertical-menu> 
    </div>
</div>
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { InvoiceData } from '@store/actions/invoice.action';
import { Invoice } from '@app/models/invoice.model';

@State<Invoice>({
	name: 'invoice',
	defaults: null
})

export class InvoiceState {

	constructor (public _store: Store) {}

	@Selector()
	static getInvoice (state: any) {
		return state.invoice;
	}

	@Action(InvoiceData)
	InvoiceData(ctx: StateContext<Invoice>, { payload }: InvoiceData): void {
		ctx.patchState({...payload});
	}
}

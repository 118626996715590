<mat-sidenav-container class="h-100">
    <div fxLayout="row" fxLayoutAlign="center center" class="h-100">
         <div fxFlex="80" fxFlex.gt-sm="30" fxFlex.sm="60" class="mat-elevation-z6">
              <app-content-header 
                  [icon]="'warning'" 
                  [title]="'403'" 
                  [hideBreadcrumb]="true"
                  [hasBgImage]="true" 
                  [class]="'pt-4 pb-3'"></app-content-header>            
              <div class="p-4 text-center">
                  <h2 class="py-2">Inactive!</h2>
                  <p class="pt-2">Sorry, you are not allowed to access this page.</p>
                  <p>Please contact the operations for more details.</p>
              </div>
          </div>
    </div>
  </mat-sidenav-container>
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteModule,
         MatBadgeModule,
         MatButtonModule,
         MatButtonToggleModule,
         MatCardModule,
         MatCheckboxModule,
         MatChipsModule,
         MatDatepickerModule,
         MatDialogModule,
         MatExpansionModule,
         MatGridListModule,
         MatIconModule,
         MatInputModule,
         MatListModule,
         MatMenuModule,
         MatNativeDateModule,
         MatPaginatorModule,
         MatProgressBarModule,
         MatProgressSpinnerModule,
         MatRadioModule,
         MatRippleModule,
         MatSelectModule,
         MatSidenavModule,
         MatSliderModule,
         MatSlideToggleModule,
         MatSnackBarModule,
         MatSortModule,
         MatTableModule,
         MatTabsModule,
         MatToolbarModule,
         MatTooltipModule,
         MatStepperModule } from '@angular/material';
import { ContentHeaderComponent } from './content-header/content-header.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BackButtonDirective } from '@directives/backbutton.directive';
import { TransactionStatusDirective } from '@directives/transaction-status.directive';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { StaffAccessComponent } from '@pages/components/staff-access/component/staff-access.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmWithInputComponent } from './confirm-with-input/confirm-with-input.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InvoiceStatusDirective } from '@app/directives/invoice-status.directive';
import { StatusDirective } from '@app/directives/status.directive';
import { CountToDirective } from '@directives/count-to.directive';
import { PageErrorComponent } from '@app/pages/errors/page-error/page-error.component';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DisableControlDirective } from '@app/directives/disable-control.directive';
import { CurrencyDirective } from '@app/directives/currency.directive';

import { ExportDialogComponent } from './export-dialog/export-dialog.component';

export const MASK_OPTIONS: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    DragDropModule,
    NgxMaskModule.forRoot(MASK_OPTIONS),
    NgxDaterangepickerMd.forRoot()
  ],
  exports: [
    FlexLayoutModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    ContentHeaderComponent,
    BreadcrumbComponent,
    NgxDatatableModule,
    BackButtonDirective,
    TransactionStatusDirective,
    InvoiceStatusDirective,
    StatusDirective,
    ConfirmationComponent,
    ConfirmWithInputComponent,
    TranslateModule,
    DragDropModule,
    CountToDirective,
    DisableControlDirective,
    PageErrorComponent,
    CurrencyDirective
  ],
  declarations: [
    ContentHeaderComponent,
    BreadcrumbComponent,
    BackButtonDirective,
    TransactionStatusDirective,
    InvoiceStatusDirective,
    StatusDirective,
    ConfirmationComponent,
    ConfirmWithInputComponent,
    CountToDirective,
    DisableControlDirective,
    PageErrorComponent,
    CurrencyDirective,
    ExportDialogComponent
  ],
  entryComponents: [
    ConfirmationComponent,
    ConfirmWithInputComponent,
    ExportDialogComponent
  ]
})
export class SharedModule { 
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}

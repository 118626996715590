import { Directive, ElementRef, Input, Renderer2, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[transactionStatus]'
})
export class TransactionStatusDirective implements OnInit {

  @Input() transactionStatus: string;

  constructor(
    private elmRef: ElementRef,
    private renderer: Renderer2,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    let text = `${this.transactionStatus}`;
    this.textTransform(text);
  }

  ngOnChanges(data){
    this.textTransform(data.transactionStatus.currentValue);
  }

  textTransform(text: string) {
    const span = this.renderer.createElement('span')  
    let innerText:string;

    
    // remove childs
    this.removeChildNode();

    switch (text.toLowerCase()) {
      case 'ack':
        let atext = this.translate.instant('general.status.ack');
        innerText = this.renderer.createText(atext || 'Acknowledged');
        this.renderer.setStyle(this.elmRef.nativeElement, 'border', '#28a745 1px solid');
        this.renderer.setStyle(this.elmRef.nativeElement, 'color', '#28a745');
        this.renderer.appendChild(span,innerText);
        break;
      
      case 'req':
        let rtext = this.translate.instant('general.status.req');
        innerText = this.renderer.createText(rtext || 'Reqeusted');
        this.renderer.setStyle(this.elmRef.nativeElement, 'border', '#6c757d 1px solid');
        this.renderer.setStyle(this.elmRef.nativeElement, 'color', '#6c757d');
        this.renderer.appendChild(span,innerText);
        break;

      default:
        break;
    }

    this.renderer.setStyle(this.elmRef.nativeElement, 'padding', '3px');
    this.renderer.appendChild(this.elmRef.nativeElement, span);
  }

  removeChildNode() {
    const childElements = this.elmRef.nativeElement.childNodes;

    for (let child of childElements) {
      this.renderer.removeChild(this.elmRef.nativeElement, child);
    }
  }
}

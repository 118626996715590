import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { AppSettings } from '../app.settings';
import { Settings } from '../app.settings.model';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {


  public settings: Settings;
  constructor(public appSettings:AppSettings, public snackBar: MatSnackBar) {
    this.settings = this.appSettings.settings; 
  }

  openSnackBar(message: string, action: string, type: string = 'badge-info') {
    
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: [type]
    });

  }
}

import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../services/language.service';
import { Language } from '../../../models/language.model'

@Component({
  selector: 'app-flags-menu',
  templateUrl: './flags-menu.component.html',
  styleUrls: ['./flags-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FlagsMenuComponent implements OnInit {

  languages: Language<any>[];
  language: Language<any>;

  public settings: Settings;
  constructor(
    public appSettings:AppSettings,
    public translate: TranslateService,
    public localeService: LanguageService
  ){
      this.settings = this.appSettings.settings; 
  }

  ngOnInit() {
    this.getLanguages();
  }

  getLanguages() {
    this.languages = this.localeService.getLanguages();
    this.language = this.getCurrentLanguage();

  }

  getCurrentLanguage() {
    return this.localeService.getCurrentLanguage();
  }

  setLanguage(code) {
    this.localeService.setCurrentLanguage(code);
    this.language = this.localeService.getCurrentLanguage();
    this.translate.use(this.language.code);
  }

}

import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import { Store } from '@ngxs/store'


@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(
    public auth: AuthService, 
    public router: Router,
    private _store: Store
  ) {}


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> | boolean {

      if (!this.auth.isAuthenticated()) {
        this.router.navigate(['/unauthorized']);
        return false;
      }

      // get role from store
      let role = this._store.selectSnapshot(state => state.auth.role);
      let isTrue:boolean = false;

      switch (role) {
        case 'admin':
          isTrue = true;
          break;

        case 'operator':
          isTrue = true;
          break;

        default:
          this.router.navigate(['/not-allowed']);
          break;
      }

      return isTrue;
    }
}

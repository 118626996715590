import { Injectable } from '@angular/core';
import { Language } from '@models/language.model';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  languages: Language<any>[] = [
    new Language({ code: 'en', name: 'English', src: 'assets/img/flags/gb.svg' }),
    new Language({ code: 'fr', name: 'French', src: 'assets/img/flags/fr.svg' }),
    new Language({ code: 'de', name: 'German', src: 'assets/img/flags/de.svg' }),
    new Language({ code: 'es', name: 'Spanish', src: 'assets/img/flags/es.svg' })
  ];

  /**
   * Get All available languages
   * 
   */
  getLanguages() {
    return this.languages;
  }

  /**
   * Get Current Language
   * 
   * Default is en-US
   */
  getCurrentLanguage() {
    const currentLang = localStorage.getItem('locale');
    const locale = this.languages.findIndex((lang) => lang.code === currentLang);
    return locale != -1 ? this.languages[locale] : this.languages[0];
  }

  /**
   * Set Language based on the constant languages
   * defined in the top
   * 
   * @param language | string
   */
  setCurrentLanguage(language) {
    const locale = this.languages.findIndex((lang) => lang.code === language);
    localStorage.setItem('locale', locale != -1 ? this.languages[locale].code : 'en');
  }
}

<div id="main" perfectScrollbar (psScrollY)="onPsScrollY($event)" class="h-100">

    <header class="header" [class.over]="showInfoContent">
        <mat-toolbar class="top-toolbar" [class.mat-elevation-z2]="settings.fixedHeader">
            <a routerLink="/" fxLayout="row" fxLayoutAlign="start center" class="logo" ngClass.xs="mobile" (click)="closeSubMenus()">
                <img src="assets/img/logo/navbar-logo.png" alt="logo">  
                <span class="mx-2">{{ 'app_title' | translate }}</span> 
            </a>
            <button *ngIf="settings.menu == 'vertical'" mat-icon-button (click)="sidenav.toggle()">
                <mat-icon>menu</mat-icon>
            </button> 
            <!-- <app-custom-menu [menuParentId]="0"></app-custom-menu> -->
            <div fxFlex></div>
            <app-flags-menu></app-flags-menu>
        </mat-toolbar>
    </header>

    <mat-drawer-container class="page-wrapper">
        <mat-drawer #sidenav *ngIf="settings.menu == 'vertical'" [opened]="settings.sidenavIsOpened" [mode]="(settings.sidenavIsPinned) ? 'side' : 'over'" (openedStart)="showSidenav = !showSidenav" (closedStart)="showSidenav = !showSidenav" class="sidenav mat-elevation-z6" autoFocus="false">
            <mat-toolbar *ngIf="settings.fixedSidenav" class="top-toolbar">
                <a routerLink="/" fxLayout="row" fxLayoutAlign="start center" class="logo">
                    <img src="assets/img/logo.png" alt="logo" [@rotate]="showSidenav">
                    <span class="mx-2">PWE Portal</span> 
                </a>
            </mat-toolbar>            
            <app-sidenav></app-sidenav>
        </mat-drawer>

        <mat-drawer-content>
            <router-outlet></router-outlet> 
        </mat-drawer-content>
    </mat-drawer-container>

    <div #backToTop fxLayout="row" fxLayoutAlign="center center" class="back-to-top" (click)="scrollToTop()">
        <mat-icon>arrow_upward</mat-icon>
    </div>

    <mat-toolbar color="primary" class="footer" [class.full-width]="!showSidenav">
        <div fxLayout="row" fxLayoutAlign="start center">
            <span class="footer-font"> &copy;&nbsp; {{ 'app_title' | translate }}</span>
        </div>
    </mat-toolbar>
</div>
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Settings } from '@app/app.settings.model';
import { AppSettings } from '@app/app.settings';

@Component({
  selector: 'app-inactive',
  templateUrl: './inactive.component.html'
})
export class InactiveComponent implements OnInit, AfterViewInit {

  public settings: Settings;
  constructor(public appSettings:AppSettings) {
    this.settings = this.appSettings.settings; 
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    this.settings.loadingSpinner = false;  
  }
}

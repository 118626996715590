import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import { Store } from '@ngxs/store';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    public auth: AuthService, 
    public router: Router,
    private _store: Store
  ) {}


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> | boolean {
      let roles: any;

      if (!this.auth.isAuthenticated()) {
        this.router.navigate(['/unauthorized']);
        return false;
      }
      
      return true;
    }
}

import { Component } from '@angular/core';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './services/language.service';
import { Language } from './models/language.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public settings: Settings;
  language: Language<any>;

  constructor(
    public appSettings:AppSettings,
    public translate: TranslateService,
    public localeService: LanguageService
  ){
    this.settings = this.appSettings.settings;
    this.language = this.localeService.getCurrentLanguage();
    translate.setDefaultLang(this.language.code);
    translate.use(this.language.code);
  } 

  ngOnInit() {}
}

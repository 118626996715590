import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ErrorComponent } from './pages/errors/error/error.component';
import { NotAllowedComponent } from './pages/errors/not-allowed/not-allowed.component';
import { UnauthorizedComponent } from '@pages/errors/unauthorized/unauthorized.component';

import { AuthGuard } from '@auth/auth.guard';
import { AdminGuard } from '@auth/admin.guard';
import { MerchantGuard } from '@auth/merchant.guard';
import { PrintLayoutComponent } from './pages/print-layout/print-layout.component';
import { TransactionDetailsPrintComponent } from './pages/print/transaction-details-print/transaction-details-print.component';
import { InactiveComponent } from './pages/errors/inactive/inactive.component';

export const routes: Routes = [
    { 
        path: '', 
        component: PagesComponent, children: [
            { path: '', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' }, canActivate: [AuthGuard]},
            
            /**
             * Admin Routes
             * 
             * 
             */
            { path: 'admin/transactions', loadChildren: () => import('./pages/admin/transactions/transactions.module').then(m => m.TransactionsModule), data: { breadcrumb: 'Transactions' }, canActivate: [AuthGuard, AdminGuard]},
            { path: 'admin/merchant-list', loadChildren: () => import('./pages/admin/merchant/merchant.module').then(m => m.MerchantModule), data: { breadcrumb: 'Merchants' }, canActivate: [AuthGuard, AdminGuard]},
            { path: 'admin/invoices', loadChildren: () => import('./pages/admin/invoices/invoices.module').then(m => m.InvoicesModule), data: { breadcrumb: 'Invoices' }, canActivate: [AuthGuard, AdminGuard]},
            { path: 'admin/daily-result', loadChildren: () => import('./pages/admin/daily-result/daily-result.module').then(m => m.DailyResultModule), data: { breadcrumb: 'Daily Result' }, canActivate: [AuthGuard, AdminGuard]},

            /**
             * Operations Routes
             * 
             * 
             */
            { path: 'operations/transactions', loadChildren: () =>  import('./pages/admin/transactions/transactions.module').then(m => m.TransactionsModule), data: { breadcrumb: 'Transactions' }, canActivate: [AuthGuard, AdminGuard]},
            { path: 'operations/merchant-list', loadChildren: () =>  import('./pages/admin/merchant/merchant.module').then(m => m.MerchantModule), data: { breadcrumb: 'Merchants' }, canActivate: [AuthGuard, AdminGuard]},
            { path: 'operations/invoices', loadChildren: () =>  import('./pages/admin/invoices/invoices.module').then(m => m.InvoicesModule), data: { breadcrumb: 'Invoices' }, canActivate: [AuthGuard, AdminGuard]},
            { path: 'operations/daily-result', loadChildren: () => import('./pages/admin/daily-result/daily-result.module').then(m => m.DailyResultModule), data: { breadcrumb: 'Daily Result' }, canActivate: [AuthGuard, AdminGuard]},
            
            /**
             * merchant Routes 
             * 
             * 
             */
            { path: 'merchant/transactions', loadChildren: () =>  import('./pages/merchant/transactions/transactions.module').then(m => m.TransactionsModule), data: { breadcrumb: 'Transactions' },  canActivate: [AuthGuard, MerchantGuard] },
            { path: 'merchant/bills', loadChildren: () =>  import('./pages/merchant/bills/bills.module').then(m => m.BillsModule), data: { breadcrumb: 'Bills' },  canActivate: [AuthGuard, MerchantGuard] },
            { path: 'merchant/staff-list', loadChildren: () =>  import('./pages/merchant/staff/staff.module').then(m => m.StaffModule), data: { breadcrumb: 'Staff' }, canActivate: [AuthGuard, MerchantGuard] },

            /**
             * merchant staff Routes 
             * 
             * 
             */
            { path: 'staff/transactions', loadChildren: () =>  import('./pages/merchant/transactions/transactions.module').then(m => m.TransactionsModule), data: { breadcrumb: 'Transactions' }, canActivate: [AuthGuard, MerchantGuard] },
            { path: 'staff/bills', loadChildren: () =>  import('./pages/merchant/bills/bills.module').then(m => m.BillsModule), data: { breadcrumb: 'Bills' }, canActivate: [AuthGuard, MerchantGuard] },

            
        ]
    },
    { path: 'print',
        outlet: 'print',
        component: PrintLayoutComponent,
        children: [
            { path: 'transaction/:id', component: TransactionDetailsPrintComponent, data: { reuse: false} }
        ],
        data: { reuse: false }
    },
    { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } },
    { path: 'unauthorized', component: UnauthorizedComponent, data: { breadcrumb: 'Unauthorized' }},
    { path: 'inactive', component: InactiveComponent, data: { breadcrumb: 'Inactive' }},
    { path: 'not-allowed', component: NotAllowedComponent, data: { breadcrumb: 'Not Allowed' } },
    { path: '**', component: NotFoundComponent }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,  // <- uncomment this line for disable lazy load
    // useHash: true
});
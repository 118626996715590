import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';

@Injectable({ providedIn: 'root' })
export class TransactionExportService {

  constructor(private _apiService: ApiService) { }

  exportTransaction(payload: any) {
    return this._apiService.post('/rtp/v1/admin/transactions/export', payload, {responseType: 'arraybuffer', observe: 'response' });
  }
}
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterParseService {

  constructor() { }

  /**
   * Encode filter params
   * 
   * @param parameters 
   * @param query 
   */
  filterParse(parameters, query) {
    if (!!query.filters) {
      Object.keys(query.filters).forEach((key) => {
        if ( query.filters[key] != '' && query.filters[key] !== null ) {
          parameters['filter['+ key +']'] = query.filters[key];
        }
      });  
    }

    if (!!query.sorts) {
      Object.keys(query.sorts).forEach((key) => {
        if ( query.sorts[key] != '' && query.sorts[key] !== null && query.sorts[key] !== undefined ) {
          parameters['sorts['+ key +']'] = query.sorts[key];
        }
      });  
    }

    return parameters;
  }
}

export const environment = {
    production: false,

    API_BASE_URL: 'https://poc.paymentworld.eu',
    APP_TITLE: 'Real-Time Payments',
  
    BEAPI: 'https://poc.paymentworld.eu/api',

    // please dont add trailing slash '/' at the end of the url
    FE_BEAPI: 'https://portal-test.paymentworld.eu',

    AUTH: {
      local: {
        login: '/login',
        google2fa: '/google2FA/enable',
        signup: '/signup'
      }
    },
    TIMEZONE: "Europe/Berlin"

  };
  
import { Menu } from './menu.model';

export const adminMenu = [
    new Menu (1, 'menu.dashboard', '/', null, 'dashboard', null, false, 0),
    new Menu (2, 'menu.invoices', '/admin/invoices', null, 'receipt', null, false, 0),
    new Menu (3, 'Daily Result', '/admin/daily-result', null, 'notes', null, false, 0),
    new Menu (4, 'menu.transactions', '/admin/transactions', null, 'swap_horiz', null, false, 0),
    new Menu (5, 'menu.merchant_list', '/admin/merchant-list', null, 'person_outline', null, false, 0)
];

export const operatorMenu = [
    new Menu (1, 'menu.dashboard', '/', null, 'dashboard', null, false, 0),
    new Menu (2, 'menu.invoices', '/operations/invoices', null, 'receipt', null, false, 0),
    new Menu (3, 'Daily Result', '/admin/daily-result', null, 'notes', null, false, 0),
    new Menu (4, 'menu.transactions', '/operations/transactions', null, 'swap_horiz', null, false, 0),
    new Menu (5, 'menu.merchant_list', '/operations/merchant-list', null, 'person_outline', null, false, 0)
];

export const merchantMenu = [
    new Menu (1, 'menu.dashboard', '/', null, 'dashboard', null, false, 0),
    new Menu (2, 'menu.bills', '/merchant/bills', null, 'receipt', null, false, 0),
    new Menu (3, 'menu.transactions', '/merchant/transactions', null, 'swap_horiz', null, false, 0),
    new Menu (4, 'menu.staff_list', '/merchant/staff-list', null, 'person_outline', null, false, 0)
];

export const staffMenu = [
    new Menu (1, 'Dashboard', '/', null, 'dashboard', null, false, 0),
    new Menu (2, 'Bills', '/staff/bills', null, 'receipt', null, false, 0),
    new Menu (3, 'Transactions', '/staff/transactions', null, 'swap_horiz', null, false, 0)
];

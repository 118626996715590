import { Component, Input, OnInit } from '@angular/core';
import { Transaction } from '@models/transaction.model';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from '@app/services/print.service';
import { Store } from '@ngxs/store';

@Component({
  selector: 'transaction-details',
  templateUrl: './transaction-details-print.component.html',
  styleUrls: ['./transaction-details-print.component.scss']
})
export class TransactionDetailsPrintComponent implements OnInit {

  data:Transaction;
  loading: boolean = false;
  
  constructor(
    route: ActivatedRoute,
    private printService: PrintService,
    private _store: Store
  ) { 
    this.data = this._store.selectSnapshot(state => state.transaction);
  }

  ngOnInit() {
    if (!!this.data) {
      this.printService.onDataReady();
    }
  }
}

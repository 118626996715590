<h2 mat-dialog-title>Confirmation</h2>
<mat-dialog-content class="pa-0 ma-0">
  <p>{{ data.message }}</p> <br>
  <p>Please enter this text <u><b>{{ data.reference_text }}</b></u> below to confirm action.</p>

  <mat-form-field appearance="outline" class="mt-3" style="width: 100%">
    <input matInput placeholder="Enter text" (input)="checkInput($event.target.value)">
  </mat-form-field>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="primary" id="yes-button" [mat-dialog-close]="true" [disabled]="!btnSubmit">Yes</button>
  <button mat-button id="no-button" [mat-dialog-close]="false">Cancel</button>
</mat-dialog-actions>

<ng-container>
  <form [formGroup]="form" (ngSubmit)="form.valid && onSubmit(form.value)">
    <div fxLayout="row" class="p-0 m-0">
      <h2 mat-dialog-title>Export Data</h2>
      <span class="flex-align-end"></span>
      <button 
        mat-icon-button
        color="primary"
        type="button"
        [disabled]="loading"
        [mat-dialog-close]="false">
          <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-divider></mat-divider>
    <mat-dialog-content class="p-4 pb-6">
      <div fxLayout="row wrap" fxLayoutGap="5px grid" fxLayout.lt-md="column">
        <div fxFlex="100">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Date Range</mat-label>
            <input matInput ngxDaterangepickerMd readonly placeholder="Placeholder" autocomplete="off" (datesUpdated)="onDatePick($event)">
          </mat-form-field>
        </div>
        <div fxFlex="100">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Merchant</mat-label>
            <mat-select formControlName="merchant">
              <mat-option [value]="null">All</mat-option>
              <mat-option *ngFor="let merchant of merchants" 
                [value]="merchant.mUUID"
                class="text-uppercase">
                {{ merchant.mName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="100">
          <h4 class="mat-body-strong">
            Columns to be exported
          </h4>
          <span *ngIf="columns.length < 1" class="mat-caption text-bold" style="color: red !important">
            *Column Fields is required
          </span>
        </div>

        <ng-container *ngFor="let ii of ' '.repeat(2).split(''); let x = index">
          <div fxFlex="50" fxLayout.lt-md="100" class="my-0">
            <section class="checkbox-group">
              <mat-checkbox *ngFor="let column of columnList.slice(x < 1 ? 0 : floorLength, x < 1 ? floorLength : columnList.length);" 
                  color="primary" 
                  [value]="column.value" 
                  (change)="onCheckChange($event)" 
                  [checked]="column.checked">
                {{ column.label }}
              </mat-checkbox>
            </section>
          </div>
        </ng-container>

      </div>
    </mat-dialog-content>
    <mat-divider></mat-divider>
    <mat-dialog-actions align="end">
      <button mat-flat-button color="primary" type="submit" [disabled]="form.invalid">
        Export
      </button>
      <button mat-flat-button [mat-dialog-close]="false" type="button">
        Cancel
      </button>
    </mat-dialog-actions>
  </form>
</ng-container>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCheckboxNamePipe } from './custom-checkbox-name/custom-checkbox-name.pipe';


@NgModule({
  imports: [ 
    CommonModule 
  ],
  declarations: [
    CustomCheckboxNamePipe
  ],
  exports: [
    CustomCheckboxNamePipe
  ]
})
export class PipesModule { }

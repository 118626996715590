import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ExportData, ColumnData } from './columns';
import { MerchantService } from '@pages/admin/merchant/merchant.service';
import { TransactionExportService } from './transaction.export.service';
import { take } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { SnackbarService } from '@services/snackbar.service';

@Component({
  selector: 'app-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss']
})
export class ExportDialogComponent implements OnInit {
  
  public merchants: any[] = [];
  public loading = false;

  public form: FormGroup;
  public columnList: ColumnData[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ExportData,
    @Inject(MAT_DIALOG_DATA) public service: any,
    private _dialogRef: MatDialogRef<ExportDialogComponent>,
    private _fb: FormBuilder,
    private _merchantService: MerchantService,
    private _transactionService: TransactionExportService,
    private _snackbarService: SnackbarService
  ) { 
    this.columnList = this.data.columns;
    
    this.form = this._fb.group({
      date_from: [null, Validators.required],
      date_to: [null, Validators.required],
      merchant: null,
      columns: this._fb.array([
        ...this.data.columns.filter(i => i.checked).map(i => i.value)
      ])
    });
  }

  get date_from() { return this.form.controls.date_from; }
  get date_to() { return this.form.controls.date_to; }
  get columns(): FormArray { return this.form.get('columns') as FormArray; }
  get columnsLength(): number { return this.columnList.length; }
  get floorLength(): number { return Math.ceil(this.columnList.length / 2); }

  ngOnInit(): void {
    this._fetchMerchants();
  }

  close(bool = false) {
    this._dialogRef.close(bool);
  }

  onCheckChange(event): void {
    const formArray = this.form.get('columns') as FormArray;
    if (event.checked) {
      formArray.push(new FormControl(event.source.value));
    } else {
      let index = formArray.controls.findIndex(i => i == event.source.value);
      formArray.removeAt(index);
    }
  }

  private _fetchMerchants() {
    this.loading = true;
    return this._merchantService.getMerchants()
      .pipe(
        take(1)
      )
      .subscribe(
        ({ data }: any) => {
          this.merchants = data;
          this.loading = false;
        },
        (err: any) => {
          this.loading = false;
        }
      )
  }

  onDatePick($event: any) {
    this.form.patchValue({
      date_from: $event.startDate.format('YYYY-MM-DD'),
      date_to: $event.endDate.format('YYYY-MM-DD')
    });
  }

  onSubmit(values: any) {
    if (this.form.invalid) {
      this._snackbarService.openSnackBar('Please complete the export form', 'close', 'badge-error');
      return false;
    }
    this.loading = true;
    return this._transactionService.exportTransaction(values)
      .pipe(
        take(1)
      )
      .subscribe(
        (res: any) => {
          this._readDownloadedFile(res);
          this.close(true);
          this.loading = false;
        },
        (err: any) => {
          const decodedString = String.fromCharCode.apply(null, new Uint8Array(err));
          const errResponse = JSON.parse(decodedString);

          this._snackbarService.openSnackBar(errResponse.message || 'There was a problem while trying to fulfill your request!', 'close', 'badge-danger');
          this.loading = false;
        }
      )
  }

  private _readDownloadedFile(data: any) {
    const blob = new Blob([(<any>data).body], { type: data.body.type });

    return saveAs(blob, 'exported-transaction.csv');
  }
}
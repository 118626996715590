import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TokenService } from './token.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router, 
    private tokenService: TokenService
  ) { }
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    /**
     * Placing of token in authorization should be
     * place here
     */
    const token = localStorage.getItem('token');
    
    if ( token ) {
      //request = request.clone({ headers: request.headers.set('Authorization', 'Bearer '+ token)});
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`
        }
      });

      request = request.clone({headers: request.headers.set('Authorization', `Bearer ${token}`)});
    }

    if ( !request.headers.has('Content-Type') ) {
      //request = request.clone({ headers: request.headers.set('Content-Type', 'application/json' )});
    }

    if ( !request.headers.has('Accept-Language') ) {
      const currentLang = localStorage.getItem('locale'); // Default is en
      request = request.clone({ headers: request.headers.set('Accept-Language', currentLang ? currentLang : 'en')})
    }

    request.headers.set('Access-Control-Allow-Origin','*');
    
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
          //
          }
        }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.router.navigate(['/login']);
            }
          }
        }
      ));
  }
}
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { Menu } from './menu.model';
import { adminMenu, operatorMenu, merchantMenu, staffMenu } from './menu';
import { Store } from '@ngxs/store';

@Injectable()
export class MenuService {

  constructor(private location:Location,
              private router:Router,
              private _store: Store){ } 
    
  public getVerticalMenuItems():Array<Menu> {
    // get roles
    const role = this._store.selectSnapshot(state => state.auth.role);

    let menu:Array<Menu>;

    switch (role) {
      case 'admin':
        menu = adminMenu;
        break;
      
      case 'merchant':
        menu = merchantMenu;
        break;

      case 'Merchant Staff':
        menu = staffMenu;
        break;

      case 'operator':
        menu = operatorMenu;
        break;

      default:
        break
    }
    
    return menu;
  }

  public customMenuItems():Array<Menu> {
    // get roles
    const role = this._store.selectSnapshot(state => state.auth.role);
    
    let menu:Array<Menu>;

    switch (role) {
      case 'admin':
        menu = adminMenu;
        break;
      
      case 'merchant':
        menu = merchantMenu;
        break;

      case 'Merchant Staff':
        menu = staffMenu;
        break;

      case 'operator':
        menu = operatorMenu;
        break;

      default:
        break
    }
    
    return menu;
  }

  public expandActiveSubMenu(menu:Array<Menu>){
      let url = this.location.path();
      let routerLink = url; // url.substring(1, url.length);
      let activeMenuItem = menu.filter(item => item.routerLink === routerLink);
      if(activeMenuItem[0]){
        let menuItem = activeMenuItem[0];
        while (menuItem.parentId != 0){  
          let parentMenuItem = menu.filter(item => item.id == menuItem.parentId)[0];
          menuItem = parentMenuItem;
          this.toggleMenuItem(menuItem.id);
        }
      }
  }

  public toggleMenuItem(menuId){
    let menuItem = document.getElementById('menu-item-'+menuId);
    let subMenu = document.getElementById('sub-menu-'+menuId);  
    if(subMenu){
      if(subMenu.classList.contains('show')){
        subMenu.classList.remove('show');
        menuItem.classList.remove('expanded');
      }
      else{
        subMenu.classList.add('show');
        menuItem.classList.add('expanded');
      }      
    }
  }

  public closeOtherSubMenus(menu:Array<Menu>, menuId){
    let currentMenuItem = menu.filter(item => item.id == menuId)[0]; 
    if(currentMenuItem.parentId == 0 && !currentMenuItem.target){
      menu.forEach(item => {
        if(item.id != menuId){
          let subMenu = document.getElementById('sub-menu-'+item.id);
          let menuItem = document.getElementById('menu-item-'+item.id);
          if(subMenu){
            if(subMenu.classList.contains('show')){
              subMenu.classList.remove('show');
              menuItem.classList.remove('expanded');
            }              
          } 
        }
      });
    }
  }

  public closeAllSubMenus(){
      let menu = document.getElementById("vertical-menu");
      if(menu){
          for (let i = 0; i < menu.children[0].children.length; i++) {
              let child = menu.children[0].children[i];
              if(child){
                  if(child.children[0].classList.contains('expanded')){
                      child.children[0].classList.remove('expanded');
                      child.children[1].classList.remove('show');
                  }
              }
          }
      }
  }
  

}

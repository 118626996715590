
export class Language<T> {
  code: string;
  name: string;
  src?: string;

  constructor(options: {
    code?: string,
    name?: string,
    src?: string
  } = {}) {
    this.code = options.code || '';
    this.name = options.name || '';
    this.src = options.src || '';
  }
}
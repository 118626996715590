import { State, Action, StateContext, Selector } from '@ngxs/store';
import { TransactionData } from '@store/actions/transaction.action';
import { Transaction } from '@app/models/transaction.model';

@State<Transaction>({
	name: 'transaction',
	defaults: null
})

export class TransactionState {

	constructor () {}

	@Selector()
	static getTransaction (state: any) {
		return state.transaction;
	}

	@Action(TransactionData)
	TransactionData(ctx: StateContext<Transaction>, { payload }: TransactionData): void {
		ctx.patchState({...payload});
	  }
}

import { State, Action, StateContext, Selector } from '@ngxs/store';
import { RoleDetails, UserDetails, StaffAccess, StaffParent, AuthStateModel, MerchantStatus, Merchants, ActiveMerchant } from '@store/actions/auth.actions';
import { AuthService } from '@auth/auth.service';
import { tap, catchError } from 'rxjs/operators';
import { empty } from 'rxjs';
import { UserRoles } from '@app/shared/RoleConstants';

const authStateDefault: AuthStateModel = {
	user: null,
	role: null,
	access: null,
	activation: null,
	merchants: null,
	selectedMerchant: null
}

@State<AuthStateModel>({
	name: 'auth',
	defaults: authStateDefault
})

export class AuthState {

	constructor (
		private authService: AuthService
	) {}

	@Selector()
	static getRole (state: any) {
		return state.auth.role;
	}

	@Selector()
	static getUser (state: any) {
		return state.auth.user;
	}

	@Selector()
	static getToken (state: any) {
		return state.auth.token;
	}

	@Selector()
	static getActiveMerchantId (state: any) {
		return state.auth.selectedMerchant;
	}

	@Selector()
	static getAccess (state: any) {
		return state.auth.access
	}

	@Action(RoleDetails)
	RoleAction({ patchState }: StateContext<AuthStateModel>) {
		return this.authService.getRole()
			.pipe(
				tap(
					(res:any) => {
						let name = res.map(r => r.name)
						patchState({ role: name[0] })
					}
				),
				catchError((err, caught) => {
					return empty();
				})
			)
	}

	@Action(UserDetails)
	User({ patchState }: StateContext<AuthStateModel>, {payload} : any) {
		let role = payload.roles.map(r => r.name);
		delete payload.roles;
		
		patchState({
			user: payload,
			role: role[0]
		});
	}

	@Action(StaffAccess)
	StaffAccess({ getState, patchState } : StateContext<AuthStateModel>) {
		let state = getState().parent || null;
		if (!state) return empty();

		return this.authService.getStaffAccess(state.id)
		.pipe(
			tap((res: any) => {
				patchState({
					access: res.data
				});
			}),
			catchError((err, caught) => {
				return empty();
			})
		)
	}

	@Action(StaffParent)
	StaffParent({ patchState } : StateContext<AuthStateModel>, { payload }: any) {
		patchState({ parent: payload, selectedMerchant: payload.id });
	}

	@Action(MerchantStatus)
	MerchantStatus({getState, patchState } : StateContext<AuthStateModel>) {
		let id = getState().role === UserRoles.MERCHANT_STAFF 
			? getState().parent.id 
			: getState().selectedMerchant;
			
		return this.authService.getMerchantStatus(id)
		.pipe(
			tap((res: any) => {
				patchState({
					activation: res.data
				});
			}),
			catchError((err, caught) => {
				return empty();
			})
		)
	}

	@Action(Merchants)
	Merchants({ getState, patchState } : StateContext<AuthStateModel>) {
		const role = getState().role;

		return this.authService.getMerchant()
		.pipe(
			tap(({data}: any) => {
				let id = null;

				if (role === UserRoles.MERCHANT) {
					id = data.length > 0 ? data[0].id : null;
				}

				if (role === UserRoles.MERCHANT_STAFF) {
					const selectedData = data.find(v => v.id === getState().selectedMerchant) || { id: null };
					id = selectedData.id || null;
				}

				patchState({
					merchants: data,
					selectedMerchant: id
				});
			}),
			catchError((err, caught) => {
				return empty();
			})
		)
	}

	@Action(ActiveMerchant)
	ActiveMerchant({ patchState } : StateContext<AuthStateModel>, { payload }) {
		patchState({
			selectedMerchant: payload
		});
	}
}

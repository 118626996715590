import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import { Store } from '@ngxs/store'
import { UserRoles } from '@app/shared/RoleConstants';


@Injectable({
  providedIn: 'root'
})
export class MerchantGuard implements CanActivate {

  constructor(
    public auth: AuthService, 
    public router: Router,
    private _store: Store
  ) {}


  canActivate(
    next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Promise<boolean> | boolean {
      let allowed = [UserRoles.MERCHANT, UserRoles.MERCHANT_STAFF];
      
      if (!this.auth.isAuthenticated()) {
        this.router.navigate(['/unauthorized']);
        return false;
      }

			// get role from store
      let auth = this._store.selectSnapshot(state => state.auth);
      
      if ( !allowed.includes(auth.role) ) {
        this.router.navigate(['/not-allowed']);
        return false;
      }

      // check merchant activation
      if (auth.role === UserRoles.MERCHANT && 
          ((!!auth.activation && !auth.activation.is_activated) || (!auth.activation))) {
            this.router.navigate(['/inactive']);
            return false;
      }

      if (auth.role === UserRoles.MERCHANT_STAFF && !auth.access.is_activated) {
        this.router.navigate(['/inactive']);
        return false; 
      }

      return true;
	}
}

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-confirm-with-input-dialog',
  templateUrl: './confirm-with-input.component.html',
  styleUrls: ['./confirm-with-input.component.scss']
})
export class ConfirmWithInputComponent implements OnInit {
  btnSubmit: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ConfirmWithInputComponent>) { }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  checkInput (value) {

    if (value === this.data.reference_text) {
      this.btnSubmit = true;
    } else {
      this.btnSubmit = false;
    }
  }
}

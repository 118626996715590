import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';
import { FilterParseService } from '@services/filter-parse.service';

@Injectable({
  providedIn: 'root'
})
export class MerchantService {

  constructor(
    public apiService: ApiService,
    public filter: FilterParseService
  ) { }

  /**
   * Get merchant list
   * 
   * @param params 
   * @param query 
   */
  getMerchantList(params: any = {}, query) {
    const parameters = {
      ...params,
      page: params.page + 1
    }

    return this.apiService.get(`/rtp/v1/operations/merchants`, this.filter.filterParse(parameters, query));
  }

  /**
   * Activate/Deactivate Merchant
   * 
   * @param merchant_id 
   * @param status 
   */
  merchantActivation(merchant_id, status) {
    return this.apiService.post('/rtp/v1/admin/merchants/activation', { merchant_id, status });
  }

  /**
   * import info on RTP
   * 
   * @param merchant_id 
   */
  importInfo( merchant_id ) {
    return this.apiService.post('/rtp/v1/admin/merchants/import', { merchant_id });
  }

  getMerchants() {
    return this.apiService.get('/rtp/v1/operations/merchants-list');
  }
}

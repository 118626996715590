import { Injectable } from '@angular/core';
import { environment as ENV } from '@environments/environment';
import { Location } from '@angular/common';
import { StorageService } from '@services/storage.service';
import { Merchants, MerchantStatus, StaffAccess, StaffParent, UserDetails } from '@store/actions';
import { Store } from '@ngxs/store';
import { UserRoles } from '@app/shared/RoleConstants';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class TokenStoreProvider {

    protected portal_url: string;
    private loadSource = new Subject<boolean>();
    load$ = this.loadSource.asObservable();

    constructor(
        private storageService: StorageService,
        private _store: Store
    ) {
        this.portal_url = Location.stripTrailingSlash(ENV.FE_BEAPI);
        localStorage.clear();
        this._store.reset({});
        this.windowListener();
    }

    /**
     * Store token
     */
    load ():Promise<any> {
        return new Promise((resolve, reject) => {
            this.load$.subscribe((e) => resolve());
        });
    }

    windowListener () {
        window.onload = () => {
            window.parent.postMessage('already_loaded', '*');
            window.onmessage = async e => {
                
                let origin = Location.stripTrailingSlash(e.origin);
    
                if ( this.removeUrlProtocol(this.portal_url) == this.removeUrlProtocol(origin)) {
                    // get parent
                    let parent = window.parent;

                    // get payload
                    const payload = JSON.parse(e.data);

                    if (payload.method == 'set') {

                        if ( !!payload.parent ) {
                            // patch state for merchant staff owner
                            this._store.dispatch(new StaffParent({ ...payload.parent }));
                        }

                        // store token to storage
                        this.storageService.setItem(payload.key, payload.data);

                        if (!payload.user) {
                            // to refresh state
                            // usually when the parent frame load from redirects
                            // the state will empty
                            return window.location.reload();
                        } else {
                            await this.patchAuth(payload);
                        }

                        // stop parent spinner
                        parent.postMessage('stop_spinner', '*');
                        this.loadSource.next(true);
                    }      
                }
            }  
        }
    }

    /**
     * Patch State Auth Details
     * 
     * @param payload 
     */
    async patchAuth(payload) {
        try {
            // dispatch user details
            const state = await this._store.dispatch(new UserDetails({...payload.user})).toPromise();

            // Merchant Roles
            const merchantRoles = [UserRoles.MERCHANT, UserRoles.MERCHANT_STAFF];

            const role = state.auth.role;
            if (!!merchantRoles.includes(role)) {
                if (role === UserRoles.MERCHANT) {
                    await this._store.dispatch(new Merchants()).toPromise();
                }

                await this._store.dispatch(new MerchantStatus()).toPromise();

                if (role === UserRoles.MERCHANT_STAFF) {
                    await this._store.dispatch(new StaffAccess()).toPromise();
                }
            }
        } catch (e) {
            // stop parent spinner
            parent.postMessage('stop_spinner', '*');
            this.loadSource.next(true);
        }
    }

    /**
     * Remove protocol on string
     * 
     * @param url 
     */
    removeUrlProtocol(url: String) : String {
        let protocols = ['https://','http://'];

        if (url.includes('https://')) {
            url.replace('https://', '');
        }

        if (url.includes('http://')) {
            url.replace('http://', '');
        }

        return url;
    }
}
import { Component, OnInit } from '@angular/core';
import { AppSettings } from '@app/app.settings';
import { Settings } from '@app/app.settings.model';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {
  protected settings: Settings;

  constructor(public appSettings:AppSettings){        
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    
  }

  ngAfterContentInit() {
    this.settings.loadingSpinner = false;
  }
}

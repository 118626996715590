import { Component, OnInit, ViewChild, QueryList, ContentChildren } from '@angular/core';
import { Store } from '@ngxs/store';
import { FormControl } from '@angular/forms';
import { ActiveMerchant, Merchants } from '@app/store/actions';
import { ConfirmationDialogService } from '@app/shared/confirmation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthState } from '@app/store/state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-switch-merchant',
  templateUrl: './switch-merchant.component.html',
  styleUrls: ['./switch-merchant.component.scss']
})
export class SwitchMerchantComponent implements OnInit {

  merchant: FormControl = new FormControl(null);
  merchantList: Array<any> = [];

  private merchants$: Observable<any>;
  constructor(
    private _store$: Store,
    private confirmDialog: ConfirmationDialogService,
    private router: Router,
    private route: ActivatedRoute
  ) { 
    
    let merchantList = _store$.selectSnapshot(state => state.auth.merchants);
    if (!merchantList) this._store$.dispatch(new Merchants());

    this.merchants$ = this._store$.select((state) => state.auth.merchants);
  }

  ngOnInit(): void {
    this.merchants$.subscribe(data =>  {
      if (!!data) this.setDefaultValue(); 
    });
  }

  setDefaultValue() {
    let {selectedMerchant, merchants} = this._store$.selectSnapshot(state => state.auth);
    this.merchantList = merchants || null;
    this.merchant.setValue(selectedMerchant || merchants[0].id);
  }

  changeMerchant(merchantControl) {
    return this.confirmDialog.openConfirmDialog(`Are you sure you want to switch to your another Merchant?`)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this._store$.dispatch(new ActiveMerchant(merchantControl.value));
          this.merchant.setValue(merchantControl.value);
          this.router.navigateByUrl('/');
        }
        this.merchant.setValue(this._store$.selectSnapshot(state => state.auth.selectedMerchant));
      });
  }
}

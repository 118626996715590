import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators';
import { ApiService } from '@services/api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  protected roles;

  constructor(
    public jwtHelper: JwtHelperService,
    private apiService: ApiService
  ) {}
  // ...
  public isAuthenticated(): boolean {
    // Check whether the token is expired and return
    // true or false
    return this.jwtHelper.isTokenExpired(this.getToken()) ? false : true;
  }

  public getRole () : Observable<Array<object>> {
    return this.apiService.get('/userRoles')
      .pipe(
        map(res => res.data)
      );
  }

  public getUser(): Observable<any>  {
    return this.apiService.get('/me')
      .pipe(
        map(res => res.data)
      );
  }
  
  private getToken () : string {
    return localStorage.getItem('token');
  }

  public getStaffAccess (parentId) : Observable<any> {
    return this.apiService.get(`/rtp/v1/merchant-staff/access/${parentId}`)
  }
  
  public getMerchantStatus (merchantId) : Observable<any> {
    return this.apiService.get(`/rtp/v1/merchant/${merchantId}/access`)
  }

  public getMerchant () : Observable<any> {
    return this.apiService.get(`/rtp/v1/user/merchants`)
  }
}

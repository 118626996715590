
import { User as UserInterface } from '@models/user.interface';

export class AuthStateModel {
    user?: UserInterface | null;
    role?: string | null;
    access?: any;
    parent?: any;
    activation?: any;
    merchants?: any;
    selectedMerchant?: any;
}

export class RoleDetails {
    static readonly type = '[Auth] Role Details';

    constructor () {}
}

export class UserDetails {
    static readonly type = '[Auth] User Details';

    constructor (public payload: any) {}
}

export class StaffAccess {
    static readonly type = '[Auth] Staff Access';

    constructor () {}
}

export class StaffParent {
    static readonly type = '[Auth] Staff Parent';

    constructor (public payload: any) {}
}

export class MerchantStatus {
    static readonly type = '[Auth] Merchant Status';

    constructor () {}
}

export class Merchants {
    static readonly type = '[Auth] Merchant List';

    constructor () {}
}

export class ActiveMerchant {
    static readonly type = '[Auth] Active Merchant';

    constructor (public payload: number) {}
}
<div id="app" class="app isPrinting" [dir]="(settings.rtl) ? 'rtl' : 'ltr'"
    [ngClass]="settings.theme" 
    [class.fixed-header]="settings.fixedHeader"
    [class.fixed-sidenav]="settings.fixedSidenav"
    [class.fixed-footer]="settings.fixedFooter"
    [class.horizontal-menu]="settings.menu == 'horizontal'" 
    [class.compact]="settings.menuType == 'compact'" 
    [class.mini]="settings.menuType == 'mini'"
>
    <router-outlet></router-outlet>
    <router-outlet name="print"></router-outlet>

    <div id="app-spinner" [class.hide]="!settings.loadingSpinner">
        <img src="assets/img/logo.png" class="animated-logo"> 
        <h4>{{ 'general.loading' | translate }}</h4>
    </div> 
</div>

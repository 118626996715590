<div id="printable-transaction" class="body-1" *ngIf="!!data">
  <div  class="p-2" fxLayout="column">
    <div fxLayout="row wrap">
      <div fxFlex="100">
        <div class="mat-title">{{ 'transaction_details.data.transaction_id' | translate }}: <span class="color-6">{{ data.refId }}</span></div>
      </div>

      <div fxFlex="49.5" class="pr-1 mb-2">
        <h3>{{ 'transaction_details.data.transaction_dates' | translate }}</h3>

        <div><b>{{ 'transaction_details.data.started_at' | translate }}:</b></div>
        <div>{{ data.timeCreated | date:"long" }}</div>
        <br>
        <div><b>{{ 'transaction_details.data.ended_at' | translate }}:</b></div>
        <div *ngIf="data.timeConcluded !== '0000-00-00 00:00:00'" class="text-6">{{ data.timeConcluded | date:"long" }}</div>
      </div>

      <div fxFlex="49.5" class="pl-1">
          <span fxFlex></span>
          <span>
            <h3 class="text-right">{{ 'transaction_details.data.gross_amount' | translate }}</h3><br>
      
            <div class="id"><span class="text-right">{{ data.amount | currency:data.currency }}</span></div>
          </span>
      </div>
    </div>

    <mat-divider [inset]="true"></mat-divider>

    <div fxLayout="row wrap">
      <div fxFlex="100">
        <h3>
            {{ 'transaction_details.data.payment_data' | translate }}
        </h3>
      </div>

      <div fxFlex="49.5" class="p-1 mb-2">
        <div><b>{{ 'transaction_details.data.payment_status' | translate }}:</b></div>
        <div><span [transactionStatus]="data.status"></span></div>
        <br>
        <div><b>{{ 'transaction_details.data.description' | translate }}:</b></div>
        <div class="text-6">{{ data.description }}</div>
      </div>

      <div fxFlex="49.5" class="p-1 mb-2">
        <div><b>{{ 'transaction_details.data.referrer_url' | translate }}: </b></div>
        <div class="text-6">{{ data.referrerURL }}</div>
      </div>
    </div>
  </div>    
</div>
